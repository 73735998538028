import type { AxiosError } from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { api } from '../../api';
import { environment } from '../../environment/environment';
import type { Org } from '../../helpers/device.helper';
import { getOrgCode } from '../../helpers/device.helper';
import { ChatPage } from '../../models/chat';
import type { ErrorMessage } from '../../models/Error';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun';

const { logError } = RaygunErrorHandlerService();

export enum APP_STATUS {
  APP_ERROR = 'error',
  APP_EXPIRED = 'expired',
  APP_LATEST = 'latest',
  APP_START = 'start',
  APP_UPDATE_AVAILABLE = 'update_available',
}

export enum APP_MODAL {
  FIRST_ALERTS_DISCLAIMER = 'disclaimer',
  FIRST_ALERTS_ONBOARDING = 'onboarding',
  FIRST_ALERTS_START = 'start',
  FIRST_ALERTS_NEW_COMMUNITY = 'new_community',
  IDLE = 'idle',
  PERMISSION = 'permission',
  UPDATE = 'update',
  ANDROID_SILENT_PROMPT = 'android_silent_prompt',
  EVOLVING_MESSAGE = 'evolving_message',
  RULES_TERMS_CONDITIONS = 'rules_terms_conditions',
  PRIVACY_POLICY = 'privacy_policy',
}

type GuestInfo = {
  guest_id: string;
  use_service_providers_coming_soon: boolean;
  use_service_providers: boolean;
  use_first_alerts: boolean;
};

type AppStatusSliceType = {
  appStatus: APP_STATUS;
  appModal: APP_MODAL;
  showNoInternetErrorToast: boolean;
  showNetworkErrorToast: boolean;
  showExpiredTokenErrorToast: boolean;
  showGetStartedButton: boolean;
  showLanguageModal: boolean;
  showLogoutModal: boolean;
  guestInfo: GuestInfo | undefined;
  settingsGoBackPage: ChatPage;
  appOrgCode: Org;
};

const initialState: AppStatusSliceType = {
  appModal: APP_MODAL.IDLE,
  appStatus: APP_STATUS.APP_START,
  showNoInternetErrorToast: false,
  showNetworkErrorToast: false,
  showExpiredTokenErrorToast: false,
  showGetStartedButton: false,
  showLanguageModal: false,
  showLogoutModal: false,
  guestInfo: undefined,
  settingsGoBackPage: ChatPage.LEPS,
  appOrgCode: getOrgCode(),
};

export const checkUpgrade = createAsyncThunk<APP_STATUS, undefined, AsyncThunkConfig>(
  'appStatus/checkUpgrade',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<APP_STATUS>('/utility/v0_upgrade', {
        app_version: environment.versionNumber,
      });
      return response ?? APP_STATUS.APP_START;
    } catch (e) {
      logError(e, ['appStatusSlice', 'checkUpgrade']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchGuestInfo = createAsyncThunk<GuestInfo | undefined, undefined, AsyncThunkConfig>(
  'appStatus/fetchGuestInfo',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<GuestInfo | undefined>('/guest/v1_get_guest_info');
      return response;
    } catch (e) {
      logError(e, ['appStatusSlice', 'fetchGuestInfo']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const setLanguage = createAsyncThunk<
  boolean | undefined,
  { onboarding: boolean; language_code: string },
  AsyncThunkConfig
>('appStatus/setLanguage', async ({ onboarding, language_code }, thunkAPI) => {
  try {
    const response = await api.post<boolean>('/guest/v0_set_language', { onboarding, language_code });
    return response;
  } catch (e) {
    logError(e, ['appStatusSlice', 'setLanguage']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const appStatusSlice = createSlice({
  name: 'appStatus',
  initialState,
  reducers: {
    setAppStatus: (state, action: PayloadAction<APP_STATUS>) => {
      state.appStatus = action.payload;
      if (action.payload === APP_STATUS.APP_START) {
        state.appModal = APP_MODAL.IDLE;
      }
    },
    setAppModal: (state, action: PayloadAction<APP_MODAL>) => {
      state.appModal = action.payload;
    },
    setNoInternetErrorToast: (state, action: PayloadAction<boolean>) => {
      state.showNoInternetErrorToast = action.payload;
    },
    setNetworkErrorToast: (state, action: PayloadAction<boolean>) => {
      state.showNetworkErrorToast = action.payload;
    },
    setExpiredTokenErrorToast: (state, action: PayloadAction<boolean>) => {
      state.showExpiredTokenErrorToast = action.payload;
    },
    setShowGetStartedButton: (state, action: PayloadAction<boolean>) => {
      state.showGetStartedButton = action.payload;
    },
    setShowLanguageModal: (state, action: PayloadAction<boolean>) => {
      state.showLanguageModal = action.payload;
    },
    setSettingsGoBackPage: (state, action: PayloadAction<ChatPage>) => {
      state.settingsGoBackPage = action.payload;
    },
    setShowLogoutModal: (state, action: PayloadAction<boolean>) => {
      state.showLogoutModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkUpgrade.fulfilled, (state, action) => {
        state.appStatus = action.payload;
      })
      .addCase(fetchGuestInfo.fulfilled, (state, action) => {
        state.guestInfo = action.payload;
      });
  },
});

export const {
  setAppStatus,
  setAppModal,
  setNoInternetErrorToast,
  setNetworkErrorToast,
  setExpiredTokenErrorToast,
  setShowGetStartedButton,
  setShowLanguageModal,
  setSettingsGoBackPage,
  setShowLogoutModal,
} = appStatusSlice.actions;
