import { RaygunErrorHandlerService } from './raygun';

const { logError } = RaygunErrorHandlerService();
const iterations = process.env.NODE_ENV === 'test' ? 2_500 : 250_000;

export const CryptoService = {
  conversions: {
    buffToBase64: (buff: ArrayBuffer): string => btoa(String.fromCharCode(...new Uint8Array(buff))),
    base64ToBuff: (b64: string) => Uint8Array.from(atob(b64), (c) => c.charCodeAt(0)),
  },

  encrypt: async (data: string, token: string, tag: string) => {
    try {
      const enc = new TextEncoder();
      const iv = window.crypto.getRandomValues(new Uint8Array(12));
      const passwordKey = await window.crypto.subtle.importKey('raw', enc.encode(token), 'PBKDF2', false, [
        'deriveKey',
      ]);
      const aesKey = await window.crypto.subtle.deriveKey(
        { name: 'PBKDF2', salt: iv, iterations, hash: 'SHA-256' },
        passwordKey,
        { name: 'AES-GCM', length: 256 },
        false,
        ['encrypt'],
      );
      const encryptedContent = await window.crypto.subtle.encrypt({ name: 'AES-GCM', iv }, aesKey, enc.encode(data));

      const buff = new Uint8Array(iv.byteLength + encryptedContent.byteLength);
      buff.set(iv, 0);
      buff.set(new Uint8Array(encryptedContent), iv.byteLength);

      return CryptoService.conversions.buffToBase64(buff.buffer);
    } catch (e) {
      logError(e, ['crypto.service', 'encrypt', tag]);
      return '';
    }
  },

  decrypt: async (encryptedData: string, token: string, tag: string) => {
    try {
      const dec = new TextDecoder();
      const encryptedDataBuff = CryptoService.conversions.base64ToBuff(encryptedData);
      const iv = encryptedDataBuff.slice(0, 12);
      const data = encryptedDataBuff.slice(12);
      const passwordKey = await window.crypto.subtle.importKey(
        'raw',
        new TextEncoder().encode(token),
        'PBKDF2',
        false,
        ['deriveKey'],
      );
      const aesKey = await window.crypto.subtle.deriveKey(
        { name: 'PBKDF2', salt: iv, iterations, hash: 'SHA-256' },
        passwordKey,
        { name: 'AES-GCM', length: 256 },
        false,
        ['decrypt'],
      );
      const decryptedContent = await window.crypto.subtle.decrypt({ name: 'AES-GCM', iv }, aesKey, data);

      return dec.decode(decryptedContent);
    } catch (e) {
      logError(e, ['crypto.service', 'decrypt', tag]);
      return '';
    }
  },
};
