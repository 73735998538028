import { isPlatform } from '@ionic/core';
import type { AnimationBuilder } from '@ionic/react';
import { createAnimation } from '@ionic/react';

import { environment } from '../environment/environment';

export const isNative = () => (isPlatform('ios') || isPlatform('android')) && !isPlatform('mobileweb');

export const setDidOnboarding = () => {
  localStorage.setItem('didOnboarding', 'true');
};

export const getDidOnboarding = () => {
  return localStorage.getItem('didOnboarding') === 'true';
};

export enum Org {
  Saskatchewan = 'sk',
  Test = 'test',
  BurnsWay = 'burns',
}

export const getOrgCode = (): Org => {
  const url = window.location.origin;
  if (environment.orgCode) {
    return environment.orgCode as Org;
  } else if (url.includes('mytest')) {
    environment.orgCode = Org.Test;
    return Org.Test;
  } else if (url.includes('theburnsway')) {
    environment.orgCode = Org.BurnsWay;
    return Org.BurnsWay;
  } else {
    environment.orgCode = Org.Saskatchewan;
    return Org.Saskatchewan;
  }
};

export const isBurns = getOrgCode() === Org.BurnsWay;
export const isSK = getOrgCode() === Org.Saskatchewan;

export const appName = isBurns ? 'the Burns Way' : 'Talking Stick';
export const infoEmail = isBurns ? 'info@theburnsway.ca' : 'info@talkingsrick.app';

export const setOrgBodyClass = () => {
  const orgCode = getOrgCode();
  if (orgCode !== Org.Saskatchewan) {
    document.body.classList.toggle(`${orgCode}-org`, true);
  }
};

export const getOrgImg = () => {
  switch (getOrgCode()) {
    case Org.BurnsWay:
      return {
        appLogo: {
          src: 'assets/imgs/tbw-loading-logo.svg',
          alt: 'the Burns Way logo',
        },
      };
    case Org.Test:
      return {
        appLogo: {
          src: 'assets/imgs/TS-LOGO.svg',
          alt: 'Talking Stick logo',
        },
        loadingImage: {
          src: 'assets/imgs/canada-goose-test-org.webp',
          alt: 'Canada goose.',
        },
      };
    default:
      return {
        appLogo: {
          src: 'assets/imgs/TS-LOGO.svg',
          alt: 'Talking Stick logo',
        },
        loadingImage: {
          src: 'assets/imgs/BISON.png',
          alt: 'Outline of a buffalo containing mountains, water, and sun moving through sky.',
        },
      };
  }
};

export const getAnimationBuilder: AnimationBuilder = (baseEl, ops) => {
  const enteringAnimation = createAnimation()
    .addElement(ops.enteringEl)
    .fill('forwards')
    .duration(150)
    .fromTo('opacity', '0', '1');
  const leavingAnimation = createAnimation().addElement(ops.leavingEl).fill('forwards').fromTo('opacity', '1', '0');
  const animation = createAnimation().addElement(baseEl).addAnimation([enteringAnimation, leavingAnimation]);
  return animation;
};

export const getEvolvingMessageStatus = () => localStorage.getItem('seenEvolvingMessage') === 'true';
export const setEvolvingMessageStatus = () => localStorage.setItem('seenEvolvingMessage', 'true');

export const getViewInternalFACommunities = () => localStorage.getItem('view_internal_fa_communities') === 'true';
export const setViewInternalFACommunities = () => localStorage.setItem('view_internal_fa_communities', 'true');
export const removeViewInternalFACommunities = () => localStorage.removeItem('view_internal_fa_communities');
