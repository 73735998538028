import type { AxiosError } from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { api } from '../../api';
import type { LabelGroup } from '../../models/advocate';
import type { ErrorMessage } from '../../models/Error';
import type { Community, Document } from '../../models/guest';
import { DocumentType } from '../../models/guest';
import type { Language } from '../../models/language';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun';

const { logError } = RaygunErrorHandlerService();

type GuestPreferenceIds = {
  label_ids: string[];
};

type GuestSliceType = {
  communityList: Community[];
  advocateLanguageList: Language[];
  preferenceOptions: LabelGroup[];
  guestLabelIds: string[];
  guestIsBlocked: boolean | undefined;
  privacyNoticeDocument: Document | undefined;
  rulesAndTermsDocument: Document | undefined;
};

const initialState: GuestSliceType = {
  communityList: [],
  advocateLanguageList: [],
  preferenceOptions: [],
  guestLabelIds: [],
  guestIsBlocked: undefined,
  privacyNoticeDocument: undefined,
  rulesAndTermsDocument: undefined,
};

export const getCommunityList = createAsyncThunk<Community[], undefined, AsyncThunkConfig>(
  'guest/getCommunityList',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<Community[]>('/guest/v1_get_communities');
      return response ?? [];
    } catch (e) {
      logError(e, ['guestSlice', 'getCommunityList']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getAdvocateLanguageList = createAsyncThunk<Language[], undefined, AsyncThunkConfig>(
  'guest/getAdvocateLanguageList',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<Language[]>('/guest/v1_get_languages');
      return response ?? [];
    } catch (e) {
      logError(e, ['guestSlice', 'getAdvocateLanguageList']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getPreferenceOptions = createAsyncThunk<LabelGroup[], undefined, AsyncThunkConfig>(
  'guest/getPreferenceOptions',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<LabelGroup[]>('/guest/v0_get_preference_options');
      return response ?? [];
    } catch (e) {
      logError(e, ['guestSlice', 'getPreferenceOptions']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getPreferences = createAsyncThunk<GuestPreferenceIds, undefined, AsyncThunkConfig>(
  'guest/getPreferences',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<GuestPreferenceIds>('/guest/v0_get_preferences');
      return response ?? { label_ids: [] };
    } catch (e) {
      logError(e, ['guestSlice', 'getPreferenceOptions']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const savePreferences = createAsyncThunk<boolean, string[], AsyncThunkConfig>(
  'guest/savePreferences',
  async (labelIds, thunkAPI) => {
    try {
      const response = await api.post<boolean>('/guest/v0_save_preferences', { label_ids: labelIds });
      return response ?? false;
    } catch (e) {
      logError(e, ['guestSlice', 'savePreferences']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const fetchDocuments = createAsyncThunk<Document[] | undefined, string, AsyncThunkConfig>(
  'guest/fetchDocuments',
  async (language_code, thunkAPI) => {
    try {
      const response = await api.post<Document[] | undefined>('/guest/v0_get_latest_documents', { language_code });
      return response;
    } catch (e) {
      logError(e, ['guestSlice', 'fetchDocuments']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getGuestBlockedStatus = createAsyncThunk<boolean | undefined, undefined, AsyncThunkConfig>(
  'guest/getGuestBlockedStatus',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<boolean>('/guest/v0_get_blocked_status');
      return response;
    } catch (e) {
      logError(e, ['guestSlice', 'getGuestBlockedStatus']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const guestSlice = createSlice({
  name: 'guest',
  initialState,
  reducers: {
    setGuestBlockedStatus: (state, action: PayloadAction<boolean>) => {
      state.guestIsBlocked = action.payload;
    },
    setGuestLabelIds: (state, action: PayloadAction<string[]>) => {
      state.guestLabelIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCommunityList.fulfilled, (state, action) => {
        state.communityList = action.payload;
      })
      .addCase(getAdvocateLanguageList.fulfilled, (state, action) => {
        state.advocateLanguageList = action.payload;
      })
      .addCase(getPreferences.fulfilled, (state, action) => {
        state.guestLabelIds = action.payload.label_ids;
      })
      .addCase(getPreferenceOptions.fulfilled, (state, action) => {
        state.preferenceOptions = action.payload;
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        action.payload?.forEach((document) => {
          if (document.document_type === DocumentType.PRIVACY_POLICY) {
            state.privacyNoticeDocument = document;
          } else {
            state.rulesAndTermsDocument = document;
          }
        });
      })
      .addCase(getGuestBlockedStatus.fulfilled, (state, action) => {
        state.guestIsBlocked = action.payload;
      });
  },
});

export const { setGuestBlockedStatus, setGuestLabelIds } = guestSlice.actions;
