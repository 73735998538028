import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { IonCheckbox, IonCol, IonLabel, IonRow, useIonLoading, useIonToast } from '@ionic/react';

import './AdvocatePostChatSurvey.scss';

import { ADVOCATE_TOAST_OPTIONS } from '../../../constants';
import { isBurns } from '../../../helpers/device.helper';
import { renderMultiSelectValue } from '../../../helpers/utils.helper';
import { useCapturePostHog } from '../../../hooks/useCapturePostHog';
import { getAdvocateSessionLog, setSessionLogsSurvey } from '../../../pages/advocate/AdvocateAccountSlice';
import { refreshToken } from '../../../pages/advocate/AdvocateAuthSlice';
import type { Subjects } from '../../../pages/advocate/AdvocateChatSlice';
import {
  getAdvocateAvailableStatus,
  resetPostChatSurvey,
  setAdvocatePostChatSurvey,
} from '../../../pages/advocate/AdvocateChatSlice';
import { getAdvocateSubjects, saveAdvocatePostChatSurvey } from '../../../pages/advocate/AdvocateChatSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { MultiSelect, TSButton, TSInput, TSSelect } from '../../atomic';
import { AdvocatePostChatSurveyContainer } from './AdvocatePostChatSurveyContainer';

const OTHER_ID = 'd2d6aade-25ad-46bd-972a-9f86148dd116';

export const AdvocateChatSessionEndedPart3 = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { capturePostHogCustomEvent } = useCapturePostHog();
  const history = useHistory();
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentToast] = useIonToast();
  const subjects = useAppSelector((state) => state.advocateChatSlice.subjects);
  const advocatePostChatSurvey = useAppSelector((state) => state.advocateChatSlice.advocatePostChatSurvey);
  const isSessionLogSurvey = useAppSelector((state) => state.advocateAccountSlice.isSessionLogSurvey);
  const sessionLog = useAppSelector((state) => state.advocateAccountSlice.sessionLog);
  const isOtherSelected = advocatePostChatSurvey.subject_ids.includes(OTHER_ID);

  useEffect(() => {
    dispatch(getAdvocateSubjects());
  }, [dispatch]);

  const validate = () => {
    let valid = true;
    const oneSubjectSelected = advocatePostChatSurvey.subject_ids.length > 0;
    const otherInput = advocatePostChatSurvey.other_subject?.trim();
    const validOther = /\p{Letter}+/gu.test(otherInput ?? '');

    if (!oneSubjectSelected) {
      valid = false;
    } else if (isOtherSelected && !validOther) {
      valid = false;
    }
    return valid;
  };

  const submit = async () => {
    const response = await dispatch(refreshToken());
    if (refreshToken.fulfilled.match(response) && response.payload.token && validate()) {
      presentLoading();
      const response = await dispatch(saveAdvocatePostChatSurvey(advocatePostChatSurvey));

      if (saveAdvocatePostChatSurvey.fulfilled.match(response) && response.payload === true) {
        capturePostHogCustomEvent('AdvocateChatSessionEndedPart3Component successfully submitted survey');
      }

      if (saveAdvocatePostChatSurvey.fulfilled.match(response) && response.payload === 'time_expired') {
        presentToast({
          ...ADVOCATE_TOAST_OPTIONS,
          message: 'Error: Survey expired. This survey ended more than an hour ago.',
          duration: 4500,
        });
        capturePostHogCustomEvent(
          'AdvocateChatSessionEndedPart3Component encountered a submission error due to survey expiry',
        );
      }

      if (saveAdvocatePostChatSurvey.rejected.match(response) && response.payload?.status !== 401) {
        presentToast({ ...ADVOCATE_TOAST_OPTIONS, message: 'Error while submitting survey.' });
        capturePostHogCustomEvent('AdvocateChatSessionEndedPart3Component encountered a submission error');
      }

      await dispatch(getAdvocateAvailableStatus());
      dispatch(resetPostChatSurvey());
      dismissLoading();

      if (isSessionLogSurvey) {
        dispatch(setSessionLogsSurvey(false));
        dispatch(getAdvocateSessionLog(sessionLog));
        history.push('/advocate/account');
      }
    }
  };

  const renderSubjectsSelection = () => {
    const selectedSubjectAlias = advocatePostChatSurvey.subject_ids.map(
      (sid) => subjects.find((s) => s.id == sid)?.alias,
    )[0];
    const selectedSubjectT = selectedSubjectAlias ? t(`advocateChatSubjects.${selectedSubjectAlias}`) : '';
    return renderMultiSelectValue(t, advocatePostChatSurvey.subject_ids.length, selectedSubjectT, '');
  };

  return (
    <AdvocatePostChatSurveyContainer>
      <IonRow>
        <IonCol className="headline-small text-bold">{t('advocatePostChatSurveyPage.title')}</IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="title-medium text-bold">{t('advocatePostChatSurveyPage.bodyNatureOfConversation')}</IonCol>
      </IonRow>
      <IonRow class="ion-padding-top">
        <TSSelect
          value={renderSubjectsSelection()}
          placeholder={t('generic.selectAllThatApply')}
          drawerProps={{ variant: isBurns ? 'dark' : 'light' }}
          inputProps={{ variant: isBurns ? 'password-input' : 'light', id: 'chat-topics' }}
          buttonVariant={isBurns ? 'burns-gold' : 'default'}
          titleContent={
            <>
              <h1 className="text-bold">{t('advocatePostChatSurveyPage.bodyNatureOfConversation')}</h1>
              <p className="text-bold">{t('generic.selectAllThatApply')}</p>
            </>
          }
          modalContent={
            <>
              <MultiSelect<Subjects>
                className={isBurns ? 'multi-select-burns' : ''}
                list={subjects}
                selectedItems={subjects.filter((s) => advocatePostChatSurvey.subject_ids.includes(s.id))}
                onSelectionChange={(selectedItems) => {
                  dispatch(setAdvocatePostChatSurvey({ subject_ids: selectedItems.map((s) => s.id) }));
                }}
                itemKeyProperty="id"
              >
                {(item) => t(`advocateChatSubjects.${item.alias}`)}
              </MultiSelect>
            </>
          }
        />
      </IonRow>
      {isOtherSelected && (
        <IonRow className="">
          <IonCol>
            <TSInput
              label={t('advocatePostChatSurveyPage.otherTopic')}
              variant={isBurns ? 'password-input' : 'light'}
              value={advocatePostChatSurvey.other_subject}
              placeholder={t('advocatePostChatSurveyPage.typeTopicHere')}
              onIonInput={(e) => dispatch(setAdvocatePostChatSurvey({ other_subject: e.detail.value }))}
            />
          </IonCol>
        </IonRow>
      )}
      <IonRow className="ion-padding-vertical">
        <IonCol>
          <IonCheckbox
            id="training-checkbox"
            className={`title-small ${isBurns ? 'tbw-checkbox' : 'ts-checkbox'}`}
            mode="md"
            labelPlacement="end"
            checked={advocatePostChatSurvey.is_training}
            onIonChange={(e) => {
              capturePostHogCustomEvent(
                `AdvocateChatSessionEndedPart3Component ${e.detail.checked ? 'checked' : 'unchecked'} training checkbox`,
              );
              dispatch(setAdvocatePostChatSurvey({ is_training: e.detail.checked }));
            }}
          >
            <IonLabel className="ion-text-wrap">{t('advocatePostChatSurveyPage.labelTrainingChat')}</IonLabel>
          </IonCheckbox>
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center ion-padding-top">
        <TSButton id="submit" variant={isBurns ? 'burns-gold' : 'black'} onClick={submit} disabled={!validate()}>
          {t('generic.submit')}
        </TSButton>
      </IonRow>
    </AdvocatePostChatSurveyContainer>
  );
};
