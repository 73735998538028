import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';

import { api } from '.';

import { environment } from '../environment/environment';
import { CryptoService } from '../services/crypto';
import { RaygunErrorHandlerService } from '../services/raygun';

const { logError } = RaygunErrorHandlerService();

const THREAD_ID_KEY = '007406ee-615f-4187-bc3d-d989026b73c3';

export const logDocumentAcknowledgement = async (document_id: string) => {
  try {
    const response = await api.post<boolean>('/guest/v0_log_document_acknowledgement', { document_id });
    return response ?? false;
  } catch (error) {
    logError(error, ['api/guest', 'logDocumentAcknowledgement']);
  }
};

export const checkDocumentsAcknowledgement = async () => {
  try {
    const response = await api.post<boolean>('/guest/v0_check_documents_acknowledgement');
    return response;
  } catch (error) {
    logError(error, ['api/guest', 'checkDocumentsAcknowledgement']);
  }
};

export const getGuestThreadId = async () => {
  if (!Capacitor.isPluginAvailable('Preferences')) {
    return;
  }

  const { value: encrypted } = await Preferences.get({ key: THREAD_ID_KEY });
  if (encrypted) {
    const decrypted = await CryptoService.decrypt(encrypted, environment.cryptoKey, 'getGuestThreadId');
    return decrypted;
  }
};

export const saveGuestThreadId = async (thread_id: string) => {
  if (!Capacitor.isPluginAvailable('Preferences')) {
    return;
  }

  const encrypted = await CryptoService.encrypt(thread_id, environment.cryptoKey, 'saveGuestThreadId');
  await Preferences.set({
    key: THREAD_ID_KEY,
    value: encrypted,
  });
};

export const removeGuestThreadId = async () => {
  if (!Capacitor.isPluginAvailable('Preferences')) {
    return;
  }

  await Preferences.remove({ key: THREAD_ID_KEY });
};
