import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  IonCol,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonRange,
  IonRow,
  IonTextarea,
  useIonToast,
} from '@ionic/react';

import { ABUSIVE_REASON_ID, ADVOCATE_TOAST_OPTIONS } from '../../../constants';
import { isBurns } from '../../../helpers/device.helper';
import { useCapturePostHog } from '../../../hooks/useCapturePostHog';
import { ADVOCATE_COMPONENT, setAdvocatePostChatSurvey } from '../../../pages/advocate/AdvocateChatSlice';
import { getAdvocateEndReasons, setAdvocateChatPageStatus } from '../../../pages/advocate/AdvocateChatSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { TSButton } from '../../atomic';
import { AdvocatePostChatSurveyContainer } from './AdvocatePostChatSurveyContainer';

const requiredCommentMessage = 'You must provide comments because abuse has been reported.';

export const AdvocateChatSessionEndedPart1 = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { capturePostHogCustomEvent } = useCapturePostHog();
  const [present] = useIonToast();
  const endReasons = useAppSelector((state) => state.advocateChatSlice.endReasons);
  const advocatePostChatSurvey = useAppSelector((state) => state.advocateChatSlice.advocatePostChatSurvey);
  const guestReportedAbuse = useAppSelector((state) => state.advocateChatSlice.guestReportedAbuse);
  const advocateReportedAbuse = useAppSelector((state) => state.advocateChatSlice.advocateReportedAbuse);
  const isAbuseReported =
    guestReportedAbuse || advocateReportedAbuse || advocatePostChatSurvey.end_reason_id === ABUSIVE_REASON_ID;
  const isAdvocateAbuseReported = advocateReportedAbuse || advocatePostChatSurvey.end_reason_id === ABUSIVE_REASON_ID;

  useEffect(() => {
    dispatch(getAdvocateEndReasons());
  }, [dispatch]);

  return (
    <AdvocatePostChatSurveyContainer>
      <IonRow>
        <IonCol className="headline-small text-bold">{t('advocatePostChatSurveyPage.title')}</IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="title-medium text-bold">{t('advocatePostChatSurveyPage.bodySelectAReason')}</IonCol>
      </IonRow>
      <IonRow className="ion-padding-top">
        <IonCol>
          <IonList className={`feedback-list ${isBurns ? 'tbw-feedback-list' : 'ts-feedback-list'}`} mode="ios">
            <IonRadioGroup
              value={advocatePostChatSurvey.end_reason_id}
              onIonChange={(e) => {
                dispatch(setAdvocatePostChatSurvey({ end_reason_id: e.detail.value }));
                capturePostHogCustomEvent('AdvocateChatSessionEndedPart1Component selected chat ended reason', {
                  end_reason_id: e.detail.value,
                });
              }}
            >
              {endReasons?.map((endReason) => (
                <IonItem key={endReason.id} id={endReason.alias} lines="none">
                  <IonRadio
                    justify="space-between"
                    mode="md"
                    value={endReason.id}
                    disabled={advocateReportedAbuse && endReason.id !== ABUSIVE_REASON_ID}
                  >
                    <IonLabel className="wrap-text">{t(`advocateChatEndReasons.${endReason.alias}`)}</IonLabel>
                  </IonRadio>
                </IonItem>
              ))}
            </IonRadioGroup>
          </IonList>
        </IonCol>
      </IonRow>
      <IonRow className="ion-padding-top">
        <IonCol>
          <IonLabel>{t('advocatePostChatSurveyPage.labelComments')}</IonLabel>
          <IonTextarea
            className={`feedback-textarea ${isBurns ? 'tbw-textarea' : 'ts-textarea'}`}
            rows={1}
            placeholder={t('generic.typeHere')}
            id="feedback"
            name="feedback"
            onIonInput={(e) => dispatch(setAdvocatePostChatSurvey({ comments: e.detail.value }))}
          />
        </IonCol>
      </IonRow>
      {isAdvocateAbuseReported && (
        <>
          <IonRow className="ion-padding-top">
            <IonCol className="title-medium text-bold">{t('advocatePostChatSurveyPage.headerHowUnsafe')}</IonCol>
          </IonRow>
          <IonRow>
            <IonRange
              className={`feedback-range ${isBurns ? 'tbw-feedback-range' : 'ts-feedback-range'}`}
              ticks={false}
              snaps={true}
              min={1}
              max={5}
              step={1}
              onIonChange={({ detail }) => {
                dispatch(setAdvocatePostChatSurvey({ abuse_rating: detail.value as number }));
                capturePostHogCustomEvent('AdvocateChatSessionEndedPart1Component selected abuse rating', {
                  rating: detail.value,
                });
              }}
            >
              <div slot="start">{t('advocatePostChatSurveyPage.labelLow')}</div>
              <div slot="end">{t('advocatePostChatSurveyPage.labelHigh')}</div>
            </IonRange>
          </IonRow>
        </>
      )}
      <IonRow className="ion-justify-content-center ion-padding-top">
        <TSButton
          id="next"
          size="small"
          className="feedback-btn"
          variant={isBurns ? 'burns-gold' : 'black'}
          disabled={!advocatePostChatSurvey.end_reason_id || (isAbuseReported && !advocatePostChatSurvey.comments)}
          onClick={() => {
            if (!advocatePostChatSurvey.end_reason_id) {
              present({ ...ADVOCATE_TOAST_OPTIONS, message: 'Please select a reason for the chat ending.' });
              capturePostHogCustomEvent(
                'AdvocateChatSessionEndedPart1Component encountered a submission error due to missing end reason',
              );
            } else if (isAbuseReported && !advocatePostChatSurvey.comments) {
              present({ ...ADVOCATE_TOAST_OPTIONS, message: requiredCommentMessage });
              capturePostHogCustomEvent(
                'AdvocateChatSessionEndedPart1Component encountered a submission error due to missing comment',
              );
            } else {
              dispatch(setAdvocateChatPageStatus(ADVOCATE_COMPONENT.POST_CHAT_SURVEY_2));
              capturePostHogCustomEvent('AdvocateChatSessionEndedPart1Component entered part 2 of the survey');
            }
          }}
        >
          {t('generic.next')}
        </TSButton>
      </IonRow>
    </AdvocatePostChatSurveyContainer>
  );
};
