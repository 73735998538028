import { useTranslation } from 'react-i18next';

import { IonCol, IonRow, useIonLoading } from '@ionic/react';

import { isBurns } from '../../helpers/device.helper';
import { useCapturePostHog } from '../../hooks/useCapturePostHog';
import type { ReportedChatBy } from '../../pages/advocate/AdvocateChatSlice';
import { saveAdvocateReportedChatBy, setOpenReportModal } from '../../pages/advocate/AdvocateChatSlice';
import { useAppDispatch, useAppSelector } from '../../store';
import { TSButton, TSModal } from '../atomic';

export const ReportChatModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { capturePostHogCustomEvent } = useCapturePostHog();
  const [presentLoading, dismissLoading] = useIonLoading();
  const openReportModal = useAppSelector((state) => state.advocateChatSlice.openReportModal);
  const advocateChatUserConn = useAppSelector((state) => state.advocateChatSlice.advocateChatUserConn);

  const close = () => {
    capturePostHogCustomEvent('ReportChatModal dismissed report chat modal', {
      thread_id: advocateChatUserConn?.threadId,
    });
    dispatch(setOpenReportModal(false));
  };

  const report = async (reportedBy: ReportedChatBy) => {
    if (advocateChatUserConn?.threadId) {
      presentLoading();
      await dispatch(
        saveAdvocateReportedChatBy({
          thread_id: advocateChatUserConn.threadId,
          reported_by_advocate: reportedBy,
        }),
      );
      capturePostHogCustomEvent('ReportChatModal advocate reported chat', {
        reason: reportedBy,
        thread_id: advocateChatUserConn.threadId,
      });
      dismissLoading();
    }
    close();
  };

  return (
    <TSModal
      className="report-chat-modal"
      variant={isBurns ? 'dark' : 'light'}
      isOpen={openReportModal}
      onDidDismiss={close}
      mainContent={
        <>
          <IonRow className="ion-padding-top">
            <IonCol className="ion-padding-top headline-small text-bold">
              {t('advocateReportChat.titleReportChat')}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>{t('advocateReportChat.bodyReportChat1')}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>{t('advocateReportChat.bodyReportChat2')}</IonCol>
          </IonRow>
          <IonRow className="ion-padding-top ion-justify-content-center">
            <TSButton
              id="report-emergency-btn"
              className="btn-fixed"
              variant={isBurns ? 'burns-gold' : 'black'}
              size="small"
              onClick={() => report('Emergency')}
            >
              {t('advocateReportChat.btnReportEmergency')}
            </TSButton>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <TSButton
              id="report-abuse-btn"
              className="btn-fixed"
              variant={isBurns ? 'burns-gold' : 'black'}
              size="small"
              onClick={() => report('Abuse')}
            >
              {t('advocateReportChat.btnReportAbuse')}
            </TSButton>
          </IonRow>
          <IonRow className="ion-padding-bottom ion-justify-content-center">
            <TSButton
              variant={isBurns ? 'burns-black' : 'white'}
              className="ion-margin-bottom btn-fixed"
              size="small"
              onClick={close}
            >
              {t('generic.cancel')}
            </TSButton>
          </IonRow>
        </>
      }
    />
  );
};
