import React from 'react';
import { PostHogProvider } from 'posthog-js/react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

import './orgHeadConfig';

import App from './App';
import { posthogClient } from './helpers/posthog.helper';
import i18n from './i18n';
import { AuthPersist } from './routes/AuthPersist';
import { store } from './store';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <PostHogProvider client={posthogClient}>
            <AuthPersist>
              <App />
            </AuthPersist>
          </PostHogProvider>
        </I18nextProvider>
      </Provider>
    </React.StrictMode>,
  );
}

// required for using Camera on web (https://capacitorjs.com/docs/web/pwa-elements)
defineCustomElements(window);
