import { useTranslation } from 'react-i18next';

import { IonGrid, IonIcon, IonRow, IonText, useIonLoading } from '@ionic/react';

import { isBurns } from '../../../helpers/device.helper';
import { getCustomIcon } from '../../../helpers/utils.helper';
import { useCapturePostHog } from '../../../hooks/useCapturePostHog';
import { type ChatEndedBy } from '../../../models/chat';
import { GuestChatActivityModalStatus } from '../../../models/guest';
import { sendChatHelpful } from '../../../pages/guest/guestChatSlice';
import { setGuestChatActivityModalStatus } from '../../../pages/guest/guestChatSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { TSButton } from '../../atomic';
import { GuestActivityModal } from './GuestActivityModal';

export const getNamespaceKey = (endedBy: ChatEndedBy) => {
  switch (endedBy) {
    case 'Guest':
      return 'GuestChatSessionHelpful.subTitleByGuestEndChat';
    case 'GuestTimeout':
      return 'GuestChatSessionHelpful.subTitleByGuestTimeout';
    case 'AdvocateReportedAbuse':
      return 'GuestChatSessionHelpful.subTitleByAdvocateReportedAbuseEndChat';
    case 'AdvocateReportedEmergency':
      return 'GuestChatSessionHelpful.subTitleByAdvocateReportedEmergencyEndChat';
    default:
      return 'GuestChatSessionHelpful.subTitleByAdvocateEndChat';
  }
};

export const GuestChatSessionHelpfulModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { capturePostHogCustomEvent } = useCapturePostHog();
  const [present, dismiss] = useIonLoading();
  const guestChatSessionHelpfulTextKey = useAppSelector((state) => state.guestChatSlice.guestChatSessionHelpfulTextKey);
  const isGuestTimeout = useAppSelector((state) => state.guestChatSlice.isGuestTimeout);
  const guestChatActivityModalStatus = useAppSelector((state) => state.guestChatSlice.guestChatActivityModalStatus);

  const handleChatHelpful = async (helpful: boolean) => {
    present();
    await dispatch(sendChatHelpful(helpful));
    dismiss();
    dispatch(setGuestChatActivityModalStatus(GuestChatActivityModalStatus.POST_CHAT_SURVEY_FEEDBACK));
    capturePostHogCustomEvent('GuestChatSessionHelpfulModal submitted if chat session was helpful or not');
    capturePostHogCustomEvent('GuestChatSessionHelpfulModal entered post-chat survey feedback modal');
  };

  return (
    <GuestActivityModal
      isOpen={guestChatActivityModalStatus === GuestChatActivityModalStatus.CHAT_HELPFUL}
      mainContent={
        <IonGrid className="ion-no-padding guest-session-helpful-wrapper">
          <IonRow>
            <IonText className="title-medium-extra text-bold modal-title">{t('GuestChatSessionHelpful.title')}</IonText>
          </IonRow>
          <div className="modal-body">
            <IonRow className="ion-margin-vertical">
              <IonText>{t(guestChatSessionHelpfulTextKey)}</IonText>
            </IonRow>
            {isGuestTimeout && (
              <IonRow className="ion-margin-vertical">
                <IonText>{t('GuestChatSessionHelpful.subTitleByAdvocateEndChat')}</IonText>
              </IonRow>
            )}
            <IonRow>
              <IonText>{t('GuestChatSessionHelpful.infoText')}</IonText>
            </IonRow>
          </div>

          <IonRow className="ion-justify-content-center">
            <TSButton
              className="ion-no-padding ion-margin-end"
              variant={isBurns ? 'burns-gold' : 'black'}
              shadow={!isBurns}
              size="small"
              shape="round"
              onClick={() => handleChatHelpful(true)}
              id="thumbs-up"
            >
              <IonIcon icon={getCustomIcon('thumbs-up')} />
            </TSButton>
            <TSButton
              className="ion-no-padding ion-margin-start"
              variant={isBurns ? 'burns-gold' : 'black'}
              shadow={!isBurns}
              fill="clear"
              size="small"
              shape="round"
              onClick={() => handleChatHelpful(false)}
            >
              <IonIcon icon={getCustomIcon('thumbs-down')} />
            </TSButton>
          </IonRow>
        </IonGrid>
      }
    />
  );
};
