import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { IonCol, IonItem, IonLabel, IonList, IonRadio, IonRadioGroup, IonRow, useIonToast } from '@ionic/react';

import { ADVOCATE_TOAST_OPTIONS } from '../../../constants';
import { isBurns } from '../../../helpers/device.helper';
import { useCapturePostHog } from '../../../hooks/useCapturePostHog';
import { ADVOCATE_COMPONENT, setAdvocatePostChatSurvey } from '../../../pages/advocate/AdvocateChatSlice';
import { getAdvocateEndReasons, setAdvocateChatPageStatus } from '../../../pages/advocate/AdvocateChatSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { TSButton } from '../../atomic';
import { AdvocatePostChatSurveyContainer } from './AdvocatePostChatSurveyContainer';

export const AdvocateChatSessionEndedPart2 = () => {
  const { t } = useTranslation();
  const { capturePostHogCustomEvent } = useCapturePostHog();
  const [present] = useIonToast();
  const dispatch = useAppDispatch();
  const advocatePostChatSurvey = useAppSelector((state) => state.advocateChatSlice.advocatePostChatSurvey);

  useEffect(() => {
    dispatch(getAdvocateEndReasons());
  }, [dispatch]);

  return (
    <AdvocatePostChatSurveyContainer>
      <IonRow>
        <IonCol className="headline-small text-bold">{t('advocatePostChatSurveyPage.title')}</IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="title-medium text-bold">{t('advocatePostChatSurveyPage.bodyReferredToSupport')}</IonCol>
      </IonRow>
      <IonRow className="ion-padding-top">
        <IonCol>
          <IonList
            className={`feedback-list ${isBurns ? 'tbw-feedback-list' : 'ts-feedback-list'}`}
            lines="none"
            mode="ios"
          >
            <IonRadioGroup
              value={advocatePostChatSurvey.referred_guest}
              onIonChange={(e) => {
                dispatch(setAdvocatePostChatSurvey({ referred_guest: e.detail.value }));
                capturePostHogCustomEvent('AdvocateChatSessionEndedPart2Component answered question on guest referral');
              }}
            >
              <IonItem lines="none">
                <IonRadio mode="md" value={true}>
                  <IonLabel>{t('generic.yes')}</IonLabel>
                </IonRadio>
              </IonItem>
              <IonItem lines="none" id="no">
                <IonRadio mode="md" value={false}>
                  <IonLabel>{t('generic.no')}</IonLabel>
                </IonRadio>
              </IonItem>
            </IonRadioGroup>
          </IonList>
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center ion-padding-top">
        <TSButton
          id="next"
          size="small"
          className="feedback-btn"
          variant={isBurns ? 'burns-gold' : 'black'}
          disabled={advocatePostChatSurvey.referred_guest == null}
          onClick={() => {
            if (advocatePostChatSurvey.referred_guest != null) {
              dispatch(setAdvocateChatPageStatus(ADVOCATE_COMPONENT.POST_CHAT_SURVEY_3));
              capturePostHogCustomEvent('AdvocateChatSessionEndedPart2Component entered part 3 of the survey');
            } else {
              present({ ...ADVOCATE_TOAST_OPTIONS, message: 'Please specify whether or not you made a referral.' });
              capturePostHogCustomEvent(
                'AdvocateChatSessionEndedPart2Component encountered a submission error on guest referral',
              );
            }
          }}
        >
          {t('generic.next')}
        </TSButton>
      </IonRow>
    </AdvocatePostChatSurveyContainer>
  );
};
